import{logEvent,logException}from"./features/logging/logging";import{useInterval}from"./utils/useInterval";const AUTO_UPDATE_CHECK_INTERVAL=60000;/**
 * Checks for new versions of the app and reloads the page if a new
 * version is available. The `no-cache` option is used to ensure that
 * the browser won't use a cached version of the entrypoint.
 */export default function AutoUpdater(){useInterval(()=>{(async()=>{let resp;try{resp=await fetch('?auto_updater=true',{cache:'no-cache'});}catch(cause){const message='Failed to fetch entrypoint to get version';const error=new Error(message,{cause});logException(error);return;}let html;try{html=await resp.text();}catch(cause){const message='Failed parsing entrypoint into text to get version';const error=new Error(message,{cause});logException(error);return;}const newVersion=extractVersion(html);if(newVersion==null)return;const presentVersion=getPresentVersion();const isNewVersionAvailable=newVersion!==presentVersion;if(!isNewVersionAvailable)return;logEvent('New version available. Reloading page.',{presentVersion,newVersion});window.location.reload();})().catch(cause=>{const message='Unknown error while checking for new version';const error=new Error(message,{cause});logException(error);});},AUTO_UPDATE_CHECK_INTERVAL);return null;}const VERSION_EXTRACT_REGEXP=/<meta name="version" content="(.*?)"/u;/**
 * Extracts the value of the version meta tag from the given HTML.
 * @returns The version when the meta tag is found. `null` when it's not found or empty.
 */function extractVersion(html){const matches=VERSION_EXTRACT_REGEXP.exec(html);if(matches==null)return null;if(matches[1]==null)return null;const raw=matches[1];const trimmed=raw.trim();if(trimmed.length===0)return null;return trimmed;}/**
 * Gets the present version of the app from the git commit hash.
 * @returns The version when the git commit hash is defined. `null` when it's not.
 */function getPresentVersion(){return"6b9152c424f5"??null;}